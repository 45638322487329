import { useEffect, useMemo, useState } from "react";
import { PAGES } from "../routes/PAGES";
import { useCommunity } from "context/community";

const documents = {
    "RU": {
        showAgreement: true,
        agreementUrl: PAGES.AGREEMENT_RU,
        policyUrl: PAGES.PRIVATE_POLICY,
        termsUrl: PAGES.TERMS_OF_USE
    },
    "EN": {
        showAgreement: false,
        agreementUrl: null,
        policyUrl: PAGES.PRIVATE_POLICY_EN,
        termsUrl: PAGES.TERMS_OF_USE_EN
    },
    "JO": {
        showAgreement: false,
        agreementUrl: null,
        policyUrl: PAGES.PRIVATE_POLICY_JO,
        termsUrl: PAGES.TERMS_OF_USE_JO
    },
    "TIM": {
        showAgreement: false,
        agreementUrl: null,
        policyUrl: PAGES.PRIVATE_POLICY_JO,
        termsUrl: PAGES.TERMS_OF_USE_JO
    }
}

const getEnv = () => {
    return process.env.REACT_APP_SERVER_ENV;
}

const getDocsConfig = (code) => {
    const env = getEnv();
    return documents[code] || documents[env] || documents["EN"];
}

const HACK_PHYSTECH4EVER = ['phystech4ever', 'brnetwork']

export const useServerProperties = () => {
    const [code, setCode] = useState();
    const env = useMemo(getEnv, []);
    const docs = useMemo(() => getDocsConfig(code), [code]);
    const { community: { domain } } = useCommunity();

    useEffect(() => {
        const fetchCountryCode = async () => {
            return await fetch('https://get.geojs.io/v1/ip/country.json')
                .then((data) => data.json())
                .then((data) => data.country)
                .then((countryCode) => {
                    let code = countryCode;
                    switch (countryCode) {
                        case "RU":
                        case "BY":
                            code = "RU";
                            break;
                        default:
                            code = "JO";
                            break;
                    }
                    setCode(code)
                })
                .catch(console.warn)
        }

        if (HACK_PHYSTECH4EVER.includes(domain)) {
            fetchCountryCode()
        }
    }, [domain])

    return {
        env,
        docs
    }
}


