import React from 'react';
import { resetUserData } from "../../services/auth";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom";
import { PAGES } from "../../routes/PAGES";
import { useCommunity, useCurrentUser } from "../../context/community";
import LangService from "../../services/LangService";
import styles from "./LoginButton.module.scss";
import cn from 'classnames';

const SHOULD_LOGIN_BE_HIDDEN = [PAGES.LOGIN, PAGES.REGISTRATION, PAGES.RECOVERY, PAGES.CONFIRM_RECOVERY,
PAGES.CONFIRMED, PAGES.AUTH_MAGIC];

const LoginButton = () => {
    const history = useHistory();
    const { resetData, community: { domain, shortLanding } } = useCommunity();
    const { userId } = useCurrentUser();
    const textMap = LangService.map.components.logout
    const onButtonClick = () => {
        gtag("event", "to_login_page");
        if (userId) {
            gtag("event", "logout");
            resetUserData();
            resetData();
        }
        history.push(PAGES.LOGIN);
    }

    const isLoginHidden = SHOULD_LOGIN_BE_HIDDEN.includes(history.location.pathname);

    return (
        !isLoginHidden && (
            <Button
                onClick={onButtonClick}
                style={cn(styles.loginBtn, {
                    [styles.shortLanding]: shortLanding
                })}
                rounded={domain !== 'nw'}
                medium
            >
                {userId ? textMap.out : textMap.in}
            </Button>
        )
    );
};

export default LoginButton;
