import React, { useState } from "react";
import styles from "./Auth.module.scss";
import { voleeApi } from "../../api/VoleeApi";
import { useCommunity, useLogin } from "../../context/community";
import { NavLink, Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { PAGES } from "../../routes/PAGES";
import { AUTH_TYPES } from "../../context/AUTH_TYPES";
import LangService from "../../services/LangService";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import validation from "./validation";
import Checkbox from "components/Checkbox/Checkbox";
import OAuthLogin from "./components/OAuthLogin";
import { useGoogleLogin } from "./GoogleLogin/useGoogleLogin";
import { GoogleLogin } from "./GoogleLogin/GoogleLogin";
import { useOneTimeCode } from "./OneTimeCode/useOneTimeCode";
import { useServerProperties } from "services/server-properties";
import { ShortAuth } from "./ShortAuth/ShortAuth";
import cn from "classnames";

const Registration = () => {
    const GTAG_EVENT = 'sign_up'
    const login = useLogin();
    const history = useHistory();
    const { docs } = useServerProperties();
    const { state } = useLocation();
    const { email: emailPassed } = state || {};
    const [email, setEmail] = useState(emailPassed || "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const { community: { domain, authConfig, logoUrl } } = useCommunity();
    const { hasCodeAuth } = useOneTimeCode();
    const hasEmail = authConfig?.findIndex(conf => conf.authType === AUTH_TYPES.EMAIL) !== -1;
    const hasOAuth2 = authConfig && authConfig.findIndex(conf => conf.authType === "OAUTH2") !== -1;
    const authMap = LangService.map.pages.Auth;
    const langMap = LangService.map.pages.Registration;
    const checkboxId = "pp-checkbox";

    const handleLoginError = (errorText) => {
        console.warn("[Login failed] res:", errorText);
        history.push(PAGES.LOGIN)
    }

    const { hasGoogle, handleGoogleSuccess, handleGoogleFailure } = useGoogleLogin({
        gtagEvent: GTAG_EVENT,
        setErrorText: handleLoginError
    })

    const handleError = () => {
        setErrorText("");
        const error =
            validation.email(email) ||
            validation.password(password) ||
            validation.confirmPassword(password, confirmPassword);

        if (error) {
            setErrorText(error);
        }

        return Boolean(error);
    };

    const loginSubmit = async () => {
        try {
            const authContext = {
                authType: AUTH_TYPES.EMAIL,
                email: email,
                password: password,
                token: null,
                backTo: null,
                introFor: null
            }
            await login(authContext);
        } catch (e) {
            handleLoginError(e.msg);
        }
    };

    const handleSubmit = async () => {
        if (disabled || handleError()) {
            return;
        }
        setDisabled(true);
        setLoading(true);
        try {
            const result = await voleeApi.auth.registerUser({ email, password });
            if (result === "OK") {
                gtag("event", GTAG_EVENT, {
                    method: "Email",
                });
                await loginSubmit();
                setDisabled(false)
            }
        } catch (error) {
            setErrorText(langMap?.error[error.msg] || error.msg);
            setDisabled(false);
        }
        setLoading(false);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            return handleSubmit();
        }
    };

    const handleCheckbox = async (checked) => {
        setDisabled(!checked)
    }

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.brandLogo}>
                    <NavLink to={PAGES.WELCOME}>
                        <img src={logoUrl} alt="brand" width="200" />
                    </NavLink>
                </div>
                <div className={styles.loginText}>{langMap.title}</div>
                <div className={styles.loginSubText}>{domain !== 'nw' ? (langMap.subtitle) : 'Создайте свой профиль в сообществе банка "Ренессанс Кредит"'}</div>
                {hasGoogle && !hasCodeAuth && (
                    <GoogleLogin
                        onGoogleSuccess={handleGoogleSuccess}
                        onGoogleFailure={handleGoogleFailure}
                    />
                )}
                {hasCodeAuth && (
                    <ShortAuth gtagEvent={GTAG_EVENT} />
                )}
                {hasOAuth2 && <OAuthLogin config={authConfig} />}
                {errorText && <div className={styles.errorText} dangerouslySetInnerHTML={{ __html: errorText }}></div>}
                {hasEmail && <>
                    {authConfig?.length > 1 &&
                        <div className={styles.orContinueWith}>
                            <span>{authMap.orContinueWith}</span>
                        </div>}
                    <Input
                        label={authMap.email}
                        type="email"
                        value={email}
                        onChange={setEmail}
                        onKeyDown={handleKeyDown}
                        name="email"
                        autoComplete="email"
                        required
                        autoFocus={!emailPassed}
                    />
                    <Input
                        label={authMap.password}
                        type="password"
                        value={password}
                        onChange={setPassword}
                        onKeyDown={handleKeyDown}
                        name="password"
                        placeholder={authMap.passwordHint}
                        autoComplete="new-password"
                        required
                        autoFocus={emailPassed}
                    />
                    <Input
                        label={authMap.repeatedPassword}
                        type="password"
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        onKeyDown={handleKeyDown}
                        name="confirm_password"
                        autoComplete="new-password"
                        required
                    />
                    <div className={styles.bottomQuestion}>
                        <div className={styles.privacyCheckbox}>
                            <Checkbox id={checkboxId} onChange={handleCheckbox} className={styles.checkbox} />
                            <label htmlFor={checkboxId} className={styles.privacyLabel}>
                                {langMap.agreePolicy[0]}
                                {domain === 'multicarta' || domain === 'ciao' ?
                                    <>{langMap.termsOfUse}</>
                                    :
                                    <a
                                        href={domain === 'nw' ?
                                            "/community/nw/assets/User_Agreement.pdf" :
                                            docs.termsUrl
                                        }
                                        target="_blank"
                                    >
                                        {langMap.termsOfUse}
                                    </a>
                                }

                                {langMap.agreePolicy[1]}
                                {domain === 'multicarta' || domain === 'ciao' ?
                                    <>{langMap.privacyPolicy}</>
                                    :
                                    <a
                                        href={domain === 'nw' ?
                                            "/community/nw/assets/Policy.pdf" :
                                            domain === 'annamoney' ?
                                                PAGES.PRIVATE_POLICY_ANNAMONEY :
                                                docs.policyUrl
                                        }
                                        target="_blank"
                                    >
                                        {langMap.privacyPolicy}
                                    </a>
                                }
                            </label>
                        </div>
                    </div>
                    {domain === 'nw' ? (
                        <Button
                            wide
                            type="button"
                            onClick={handleSubmit}
                            style={styles.submitButton}
                            disabled={disabled}
                        >
                            {langMap.button}
                        </Button>
                    ) : (
                        <Button
                            wide
                            type="button"
                            onClick={handleSubmit}
                            style={styles.submitButton}
                            active
                            disabled={disabled}
                            loading={loading}
                        >
                            {langMap.button}
                        </Button>
                    )}
                </>}
                <p className={cn(styles.bottomQuestion, {
                    [styles.shortAuth]: hasCodeAuth
                })}>
                    {langMap.accountQuestion} <Link className={styles.link} to={PAGES.LOGIN}>{langMap.link}</Link>
                </p>
            </div>
        </div>
    );
};

export default Registration;
