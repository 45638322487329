import React from "react";
import styles from "./Button.module.scss"
import cn from "classnames";

const Button = ({ title, disabled, onClick, children, style, wide, small, medium, active, rounded, community, danger, outline, secondary, primary, icon, iconOnly, loadMoreBtn, loading, htmlTitle, animationHolder }) =>
    <div className={cn(styles.wrapper, {
        [styles.wide]: wide,
        [styles.small]: small,
        [styles.medium]: medium,
        [styles.active]: active,
        [styles.community]: community,
        [styles.rounded]: rounded,
        [styles.danger]: danger,
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.outline]: outline,
        [styles.icon]: icon,
        [styles.iconOnly]: iconOnly,
        [styles.loadMoreBtn]: loadMoreBtn,
        [styles.loading]: loading,
    }, style)}>

        <button
            title={htmlTitle || ''}
            className={disabled ? styles.disabled : ""}
            disabled={disabled}
            onClick={onClick}>
            {loading
                ? <div className={styles.loader}></div>
                : <> {icon && (icon)}
                    {animationHolder}
                    {title || children}</>}

        </button>

    </div>

export default Button