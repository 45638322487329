import LangService from "../../../services/LangService";
import { useLogin } from "../../../context/community";
import { useCommunity } from "../../../context/community";
import { AUTH_TYPES } from "../../../context/AUTH_TYPES";

export function useGoogleLogin({
    gtagEvent = 'login',
    setErrorText = (msg) => {}
}) {
    const { community: { authConfig } } = useCommunity();
    const login = useLogin();
    const errorsMap = LangService.map.errors;

    const hasGoogle = authConfig?.findIndex(conf => conf.authType === AUTH_TYPES.GOOGLE) !== -1;

    const loginSubmit = async (googleTokenId) => {
        try {
            const authContext = {
                authType: AUTH_TYPES.GOOGLE,
                email: '',
                password: '',
                token: googleTokenId,
                backTo: null,
                introFor: null
            }
            await login(authContext);
        } catch (e) {
            if (e.msg === "Invalid login or password") {
                e.msg = errorsMap[1011];
            }
            if (e.msg === "Access Denied") {
                e.msg = errorsMap[1005];
            }
            setErrorText(e.msg);
        }
    };

    const handleGoogleSuccess = async (res) => {
        gtag("event", gtagEvent, {
            method: "Google",
        });
        await loginSubmit(res.tokenId);
    };

    const handleGoogleFailure = (res) => {
        console.warn("[GoogleLogin failed] res:", res);
    };

    return {
        hasGoogle,
        handleGoogleSuccess,
        handleGoogleFailure
    }
}