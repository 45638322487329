import React from 'react';
import LangService from 'services/LangService';
import styles from "./NavBar.module.scss";
import { Col } from 'react-grid-system';
import { ReactComponent as HeartIcon } from '../../assets/Imgs/heart.svg';

const StripeButton = () => {
  const langMap = LangService.map.components.header;
  const numbers = Array.from({ length: 52 }, (_, index) => index + 1);
  return (
    <Col xs={12} md="content" className={styles.donateCol}>
      <a className={`${styles.navButton} ${styles.donate}`}
        href='https://buy.stripe.com/6oE17iat734D1Ta9AD'
        target='_blank'
        rel='noreferrer'
      >
        <HeartIcon />
        {langMap.donate}
        <div className={styles.spots}>
          {numbers.map((number, index) => (
            <div key={index} className={styles.buttonSpots}></div>
          ))}
        </div>
      </a>
    </Col>
  )
}

export default StripeButton;