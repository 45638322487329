import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "reset-css"
import "typeface-open-sans"
import "typeface-roboto";
import App from "./App"
import "./index.css"
import "./fonts.css"
import { ProvideCommunity } from "context/community";
import { PopupNotificationProvider } from "context/PopupNotificationProvider"
import { WebSocketProvider } from "context/realtime/webSocket"
import { OnlineStatusProvider } from "context/realtime/onlineStatus"

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ProvideCommunity>
                <WebSocketProvider>
                    <OnlineStatusProvider>
                        <PopupNotificationProvider>
                            <App />
                        </PopupNotificationProvider>
                    </OnlineStatusProvider>
                </WebSocketProvider>
            </ProvideCommunity>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("app")
)