import { getDomain } from "utils";
import { getToken, resetToken } from "../services/auth";
import { getMyIp } from "../services/ip";

const axios = require('axios');
const environment = process.env.NODE_ENV || "development";
const isProd = environment === "production";

const defaultHeaders = async () => {
    return {
        "Content-Type": "application/json;charset=UTF-8",
        "Public-Ip": await getMyIp(),
        "Community": getDomain()
    }
}

const getAuthorizationHeader = () => {
    const token = getToken();
    return Boolean(token) ? ({
        "Authorization": `Bearer ${getToken()}`,
    }) : {};
}

const parseError = (error) => {
    let parsedError = {
        code: 1000,
        msg: ""
    }
    if (error && error.response && error.response.data) {
        let msg = error.response.data.message || error.response.data.error;
        if (typeof msg === "string") parsedError.msg = msg.trim().replace('[', '').replace(']', '');
        let code = error.response.data.code || error.response.data.status;
        if (code) parsedError.code = code;
    }
    if ([405].includes(parsedError.code)) {
        resetToken();
        // eslint-disable-next-line no-restricted-globals
        location.assign('/login');
    }

    if (parsedError.code === 503 || error.message === 'ERR:TIMEOUT' || error.message === 'ERR:ERR_FAILED' || error.message === 'Network Error' || error.response?.status === 0) {
        dispatchErrorEvent('error', 'Server is unavailable');
    }

    sendToSentry(error);

    return parsedError;
}

function dispatchErrorEvent(eventName, message) {
    const errorEvent = new CustomEvent(eventName, {
        detail: { message }
    });

    window.dispatchEvent(errorEvent);
}

async function sendToSentry(error) {
    if (!isProd) return;
    try {
        const Sentry = await import("@sentry/react");
        Sentry.captureException(error);
    } catch (e) {
        console.error('not sent to Sentry', e);
    }
}

export const get = async (url, params, headers) => {
    return axios({
        method: 'get',
        url: url,
        params: params,
        headers: Object.assign({}, await defaultHeaders(), getAuthorizationHeader(), headers),
    })
        .then(r => r.data)
        .catch(e => Promise.reject(parseError(e)))
}

export const post = async (url, params, body, headers) => {
    return axios({
        method: 'post',
        url: url,
        params: params,
        data: body,
        headers: Object.assign({}, await defaultHeaders(), getAuthorizationHeader(), headers),
    })
        .then(r => r.data)
        .catch(e => Promise.reject(parseError(e)))
}

export const patch = async (url, params, body, headers) => {
    return axios({
        method: 'patch',
        url: url,
        params: params,
        data: body,
        headers: Object.assign({}, await defaultHeaders(), getAuthorizationHeader(), headers),
    })
        .then(r => r.data)
        .catch(e => Promise.reject(parseError(e)))
}

export const del = async (url, params, body, headers) => {
    return axios({
        method: 'delete',
        url: url,
        params: params,
        data: body,
        headers: Object.assign({}, await defaultHeaders(), getAuthorizationHeader(), headers),
    })
        .then(r => r.data)
        .catch(e => Promise.reject(parseError(e)))
}

export const sseEventSource = async (url) => {
    return new EventSource(url, {
        withCredentials: true
    });
}