import locals from "../constants/locals";

const langs = {
    RU: "RU",
    EN: "EN"
}

class LangService {
    constructor() {
        const lang = langs.EN;
        this._lang = lang
        this._map = locals[lang]
    }

    get lang() {
        return this._lang
    }

    set lang(lang) {
        const newLang = (lang || langs.EN).toUpperCase();
        localStorage.setItem("local", newLang)
        this._lang = newLang
        this._map = locals[newLang]
    }

    get map() {
        return locals[this.lang]
    }
}

export default new LangService()
