import { useState } from "react";
import { useGoogleLogin } from "../GoogleLogin/useGoogleLogin";
import { GoogleLogin } from "../GoogleLogin/GoogleLogin";
import { useHistory } from "react-router-dom";
import { PAGES } from "../../../routes/PAGES";
import LangService from "../../../services/LangService";
import validation from "../validation";
import styles from "./ShortAuth.module.scss";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import { useCommunity } from "../../../context/community";
import { useServerProperties } from "services/server-properties";
import cn from "classnames";

const HACK_PHYSTECH4EVER = ['phystech4ever', 'brnetwork']

export const ShortAuth = ({
    inputRef = null,
    gtagEvent = 'welcome',
    isLanding = false,
    buttonText = '',
    nextPage = PAGES.ONE_TIME_CODE
}) => {
    const GTAG_EVENT = 'time_code_' + gtagEvent;
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("");
    const history = useHistory();
    const { community: { domain, parentCommunityDomain } } = useCommunity();
    const langMap = LangService.map.pages.Registration;
    const checkboxId = "pp-checkbox";
    const { docs } = useServerProperties();
    const [disabled, setDisabled] = useState(true);

    const isCheckboxShown = () => {
        return HACK_PHYSTECH4EVER.includes(domain) && !isLanding;
    }

    const handleLoginError = (errorText) => {
        console.warn("[Login failed] res:", errorText);
        history.push(PAGES.LOGIN)
    }
    const { hasGoogle, handleGoogleSuccess, handleGoogleFailure } = useGoogleLogin({
        gtagEvent: GTAG_EVENT,
        setErrorText: handleLoginError
    })
    const authMap = LangService.map.pages.Auth;

    const handleError = () => {
        setErrorText("");
        const error = validation.email(email);
        if (error) {
            setErrorText(error);
            setTimeout(() => {
                setErrorText("");
            }, 2500);
        }

        return Boolean(error);
    };

    const loginSubmit = async () => {
        if (parentCommunityDomain) {
            const newOrigin = window.location.origin.replace(domain, parentCommunityDomain);
            const realNextPage = new URL(nextPage, newOrigin);
            realNextPage.searchParams.append('email', encodeURIComponent(email));
            window.location.href = realNextPage;
            return;
        }

        history.push(nextPage, {
            email
        });
    };

    const handleSubmit = async () => {
        if ((isCheckboxShown() && disabled) || handleError()) {
            return;
        }
        gtag("event", GTAG_EVENT, {
            method: "Email",
        });
        await loginSubmit();
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            return handleSubmit();
        }
    };

    const handleCheckbox = async (checked) => {
        setDisabled(!checked)
    }

    return (
        <>
            {hasGoogle &&
                <>
                    <GoogleLogin
                        onGoogleSuccess={handleGoogleSuccess}
                        onGoogleFailure={handleGoogleFailure}
                    />
                    <div className={styles.orContinueWith}>
                        <span>{authMap.or}</span>
                    </div>
                </>}
            <div className={cn(styles.container, {
                [styles.isLanding]: isLanding
            })}>
                <Input
                    placeholder={authMap.emailPlaceholder}
                    type="email"
                    value={email}
                    onChange={setEmail}
                    onKeyDown={handleKeyDown}
                    name="email"
                    autoComplete="email"
                    required
                    className={styles.input}
                    inputRef={inputRef}
                    label={errorText}
                    hasError={errorText}
                />

                {isCheckboxShown() &&
                    <div className={styles.bottomQuestion}>
                        <div className={styles.privacyCheckbox}>
                            <Checkbox id={checkboxId} onChange={handleCheckbox} className={styles.checkbox} />
                            <label htmlFor={checkboxId} className={styles.privacyLabel}>
                                {langMap.agreePolicy[0]}
                                <a
                                    href={docs.termsUrl}
                                    target="_blank"
                                >
                                    {langMap.termsOfUse}
                                </a>

                                {langMap.agreePolicy[1]}
                                <a
                                    href={docs.policyUrl}
                                    target="_blank"
                                >
                                    {langMap.privacyPolicy}
                                </a>
                            </label>
                        </div>
                    </div>
                }
                <Button
                    wide
                    disabled={Boolean(!email) || errorText}
                    type="button"
                    onClick={handleSubmit}
                    style={styles.submitButton}
                    active
                >
                    {buttonText || authMap.shortAuthButton}
                </Button>
            </div>
        </>
    )
}
