import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import styles from '../Header.module.scss';
import { ReactComponent as LangIcon } from '../assets/multilang.svg';
import LangService from 'services/LangService';
import { useCurrentUser } from 'context/community';
import { voleeApi } from 'api/VoleeApi';

const LangSelector = ({ langChoices }) => {
    const { userId } = useCurrentUser();
    const [showDropdown, setShowDropdown] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const langIconRef = useRef(null);

    const nativeLang = {
        "RU": "РУ",
        "EN": "EN"
    }

    const handleIconClick = () => {
        if (!showDropdown) {
            gtag("event", `lang_selector_opened`);
        }
        setShowDropdown(!showDropdown);
        setIsActive(!isActive);
    };

    const handleOutsideClick = (event) => {
        if (showDropdown && langIconRef.current && !langIconRef.current.contains(event.target)) {
            setShowDropdown(false);
            setIsActive(false);
        }
    };

    const handleLangChange = async (lang) => {
        if (LangService.lang !== lang) {
            LangService.lang = lang;
            gtag("event", `lang_selector_changed`);
            if (userId) {
                await voleeApi.general.saveLangChoice(lang)
            }

            // todo: should change on the fly
            window.location.reload()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });

    if (!langChoices) {
        return <></>
    }

    return (
        <div className={cn(styles.langSelectorWrapper, {
            [styles.hasUser]: userId
        })}>
            <div className={cn(styles.langSelector, {
                [styles.active]: isActive

            })}>
                <LangIcon ref={langIconRef} onClick={handleIconClick} />
            </div>
            {showDropdown && (
                <div className={cn(styles.langOptions)}>
                    {langChoices.map((lang) => (
                        <div className={cn(styles.langOption, { [styles.activeLang]: LangService.lang === lang })} data-active={LangService.lang === lang} key={lang} onClick={() => handleLangChange(lang)}>
                            {nativeLang[lang]}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LangSelector;
