const TOKEN = 'token'
const FIRST_LOGIN = 'firstLogin'
const REFERENCE_COMMUNITY_ID = 'referenceCommunityId'
const HAS_LOGGED_IN = 'has_logged_in'
const AUTHORITIES = 'authorities'
const ANSWERS = 'answers'
const PROFILE = 'profile'
const STEP = 'step'
const EMAIL = 'email'
const INVITATED_BY = 'invitedBy'

export const getToken = () => localStorage.getItem(TOKEN);
export const setToken = (token) => {
    localStorage.setItem(TOKEN, token)
    document.cookie = `session=${token.split('.')[0]}; secure; samesite; path=/`
};
export const resetToken = () => {
    localStorage.removeItem(TOKEN)
    document.cookie = "session=0; secure; samesite; max-age=0; path=/"
};

export const getFirstLogin = () => localStorage.getItem(FIRST_LOGIN);
export const setFirstLogin = (firstLogin) => localStorage.setItem(FIRST_LOGIN, firstLogin ? 1 : '');
export const resetFirstLogin = () => localStorage.removeItem(FIRST_LOGIN);

export const getRole = () => localStorage.getItem(AUTHORITIES) || '';
export const setRole = (role) => {
    localStorage.setItem(AUTHORITIES, role);
}
export const resetRole = () => localStorage.removeItem(AUTHORITIES);

export const isAdmin = () => getRole().includes('ROLE_ADMIN');
export const isUser = () => getRole().includes('ROLE_USER');

export const getReferenceCommunityId = () => localStorage.getItem(REFERENCE_COMMUNITY_ID);
export const setReferenceCommunityId = (communityId) => localStorage.setItem(REFERENCE_COMMUNITY_ID, communityId);
export const resetReferenceCommunityId = () => localStorage.removeItem(REFERENCE_COMMUNITY_ID);

export const getLoggedIn = () => localStorage.getItem(HAS_LOGGED_IN);
export const setLoggedIn = () => localStorage.setItem(HAS_LOGGED_IN, 1);
export const resetLoggedIn = () => localStorage.removeItem(HAS_LOGGED_IN);

export const getStep = () => Number(localStorage.getItem(STEP));
export const setStep = (step) => localStorage.setItem(STEP, Number(step));
export const resetStep = () => localStorage.removeItem(STEP);

export const getEmail = () => localStorage.getItem(EMAIL);
export const setEmail = (email) => localStorage.setItem(EMAIL, email);
export const resetEmail = () => localStorage.removeItem(EMAIL);

export const getAnswers = () => JSON.parse(localStorage.getItem(ANSWERS) || null);
export const setAnswers = (answers) => localStorage.setItem(ANSWERS, JSON.stringify(answers));
export const resetAnswers = () => localStorage.removeItem(ANSWERS);

export const getProfile = () => JSON.parse(localStorage.getItem(PROFILE) || null);
export const setProfile = (profile) => localStorage.setItem(PROFILE, JSON.stringify(profile));
export const resetProfile = () => localStorage.removeItem(PROFILE);

export const getInvitedBy = () => localStorage.getItem(INVITATED_BY);
export const setInvitedBy = (invitedBy) => localStorage.setItem(INVITATED_BY, invitedBy);
export const resetInvitedBy = () => localStorage.removeItem(INVITATED_BY);

export const resetUserData = () => {
    resetToken();
    resetRole();
    resetLoggedIn();
    resetAnswers();
    resetProfile();
    resetFirstLogin();
}
