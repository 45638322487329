import React, { useMemo } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { PAGES } from "../../routes/PAGES";
import styles from "./NavBar.module.scss";
import { getToken, isAdmin, isUser } from "../../services/auth";
import { useCommunity, useCurrentUser } from "../../context/community";
import LangService from "../../services/LangService";
import { Col } from "react-grid-system";
import { ReactComponent as ProfileIcon } from "assets/Imgs/profile.svg";
import { ReactComponent as FindPeopleIcon } from "assets/Imgs/find_people.svg";
import { ReactComponent as CommunityIcon } from "assets/Imgs/community.svg";
import { ReactComponent as MatchesIcon } from "assets/Imgs/matches.svg";
import { ReactComponent as RequestIcon } from "assets/Imgs/requests.svg";
import { ReactComponent as AnalyticsIcon } from "assets/Imgs/assessment.svg";
import { ReactComponent as SettingsIcon } from 'assets/Imgs/settings.svg';
import { useWindowDimensions } from 'context/window';
import StripeButton from './StripeButton';


const SHOULD_BE_HIDDEN = [PAGES.QUESTIONS, PAGES.ONBOARDING, PAGES.LAST_STEP, PAGES.CONFIRMED];
const DONATE_SHOWN_AT = [PAGES.MY_COMMUNITIES];
const NavBar = ({ showDonation }) => {
    const history = useHistory();
    const { connectedParticipants, enabled, slug } = useCurrentUser();
    const { community } = useCommunity();
    const { width } = useWindowDimensions();
    const admin = isAdmin();
    const user = isUser();
    const token = getToken();
    const pagesMap = LangService.map.pages;
    // Show the donation button if:
    // - The parent allows it
    // - It's a specific community
    // - The user is not an admin
    // - On mobile: only on the designated page
    // - On desktop: everywhere
    const donation = showDonation &&
        community.domain == "phystech4ever" &&
        !admin &&
        (DONATE_SHOWN_AT.includes(history.location.pathname) || width > 768);

    const navbarLinks = useMemo(() => {
        const navbarLinks = [];

        if (user) {

            if (enabled === false) {
                navbarLinks.push(
                    {
                        to: PAGES.PROFILE,
                        text: pagesMap.Profile.menuText,
                        icon: <ProfileIcon />
                    }
                );
                history.push(PAGES.PROFILE);
                return navbarLinks;
            }

            navbarLinks.push(
                {
                    to: PAGES.MY_COMMUNITIES,
                    text: pagesMap.MyCommunities.menuText,
                    icon: <CommunityIcon />
                })
            community.transparent && navbarLinks.push(
                {
                    to: PAGES.PARTICIPANTS,
                    text: pagesMap.Participants.menuText,
                    icon: <FindPeopleIcon />
                },
            )
            navbarLinks.push(
                {
                    to: PAGES.CONTACTS,
                    text: pagesMap.Contacts.menuText,
                    icon: <MatchesIcon />
                },
            )
            if (width > 768) {
                navbarLinks.push(
                    {
                        to: "/u/" + slug, // redirects to PAGES.FULL_PROFILE_VIEW with self slug
                        text: pagesMap.Profile.menuText,
                        icon: <ProfileIcon />
                    }
                )
            }
        } else if (admin) {
            navbarLinks.push(
                {
                    to: PAGES.EVENT,
                    text: pagesMap.Event.menuText,
                    icon: <CommunityIcon />
                },
                {
                    to: PAGES.DASHBOARD,
                    text: pagesMap.Admin.analytics,
                    icon: <AnalyticsIcon />
                },
                {
                    to: PAGES.NOTIFICATIONS,
                    text: pagesMap.Notifications.menuText,
                    icon: <RequestIcon />
                },
            )

            // if (width > 768) {
            //     navbarLinks.push(
            //         {
            //             to: PAGES.SETTINGS,
            //             text: pagesMap.AdminSettings.menuText,
            //             icon: <SettingsIcon />
            //         },
            //     )
            // }
        }

        return navbarLinks;
    }, [community.transparent, user, admin, enabled, LangService.lang, slug, width]);

    if (!token || (user && !connectedParticipants?.length) || SHOULD_BE_HIDDEN.includes(history.location.pathname)) {
        return null;
    }
    return (
        <>
            {
                navbarLinks.map(({ to, text, icon }) => (
                    <Col xs="content" key={text}>
                        <NavLink
                            to={to}
                            onClick={() => {
                                gtag('event', `navbar_to_${to.replace('/', '')}`, {
                                    from: history.location.pathname,
                                    to
                                })
                            }}
                            className={styles.navButton}
                            activeClassName={styles.navActiveButton}
                        >
                            {icon}
                            {text}
                        </NavLink>
                    </Col>
                ))
            }
            {donation && <StripeButton />}
        </>
    );
};

export default NavBar;
