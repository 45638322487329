import React from 'react';
import cn from "classnames";
import styles from "./PopupNotification.module.scss";
import { usePopupNotification } from 'context/PopupNotificationContext';
import { ReactComponent as CloseIcon } from "../../assets/Imgs/close.svg";

const PopupNotification = () => {
    const { notificationQueue = [], hideNotification } = usePopupNotification();
    return (
        <div className={styles.popupContainer}>
            {notificationQueue.map(n => {
                return (
                    <div key={n.key} className={cn(styles.popup, {
                        [styles.error]: n.type === 'error',
                        [styles.default]: n.type === 'default',
                        [styles.shown]: n.shown,
                        [styles.hidden]: !n.shown,
                    })}>
                        <div className={styles.header}>
                            {n.title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: n.title }}></div>}
                            <CloseIcon className={styles.closeIcon}
                                onClick={() => hideNotification(n.key)}
                            />
                        </div>
                        {n.content && <div className={styles.content}>{n.content}</div>}
                    </div>
                )
            })}
        </div>
    );
};

export default PopupNotification;
