import React from "react";
import styles from "./Checkbox.module.scss"
import { newId } from '../../utils';
import cn from "classnames";

const Checkbox = ({
    label,
    onChange,
    className,
    id,
    ...props
}) => {
    const _id = id || newId()
    return (<div className={cn(styles.wrapper, className)}>
        <input
            id={_id}
            className={styles.checkbox}
            onChange={event => onChange(event.target.checked)}
            type="checkbox"
            {...props}
        />
        <label htmlFor={_id}>
            {label}
        </label>
    </div>)
}


export default Checkbox
