import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

const HOST = process.env.REACT_APP_API_HOST;
const WEBSOCKET_URL = `${HOST}/api/ws`;
const TOPICS_PATH = '/topic/';

let stompClient = null;

const connect = (token, onConnect) => {
    const urlWithToken = WEBSOCKET_URL + '?token=' + encodeURIComponent(token);
    stompClient = new Client({
        brokerURL: urlWithToken,
        webSocketFactory: () => new SockJS(urlWithToken),
        onConnect: onConnect,
        onStompError: frame => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        }
    });
    stompClient.activate();
};


const disconnect = () => {
    if (stompClient !== null) {
        stompClient.deactivate();
    }
};

const sendMessage = (message, destination) => {
    stompClient.publish({ destination: destination, body: JSON.stringify(message) });
};

const subscribe = (topic, callback) => {
    return stompClient.subscribe(`${TOPICS_PATH}${topic}`, message => {
        callback(JSON.parse(message.body));
    });
}

export const webSockets = {
    connect,
    subscribe,
    sendMessage,
    disconnect
};
