import React from "react";
import Button from "components/Button/Button";
import styles from "../Auth.module.scss";

const OAuthLogin = ({
    config
}) => {
    const oAuthConf = config[config.findIndex(conf => conf.authType === "OAUTH2")];
    const externalContent = JSON.parse(oAuthConf.externalContent);
    return <Button
        style={styles.oauth2Button}
        active
        wide
        onClick={() => {
            window.location.href = externalContent.codeUrl;
        }}
    >
        {/* <img src={externalContent.logoUrl} alt="provider logo" /> */}
        Sign Up
    </Button>
}


export default OAuthLogin;