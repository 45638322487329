import React, { useState } from "react";
import styles from "./Input.module.scss"
import cn from "classnames";
import { ReactComponent as CloseIcon } from "../../assets/Imgs/close.svg";
import { newId } from "utils";

const Input = ({
    label,
    onChange,
    className,
    icon,
    showClear,
    inputRef,
    hasError,
    children,
    ...props
}) => {
    const [hasFocus, setHasFocus] = useState(false)
    const id = props.id || newId();
    const handleFocus = () => {
        setHasFocus(!hasFocus)
    }
    return (<div className={cn(styles.input, className, {
        [styles.icon]: icon,
        [styles.hasFocus]: hasFocus,
        [styles.error]: hasError
    })}>
        {label ? <label className={styles.label} htmlFor={id}>{label + (props.required ? " *" : "")}</label> : null}
        {icon && (<div className={styles.inputIcon}>{icon}</div>)}
        <input
            id={id}
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleFocus}
            type="text"
            onChange={event => onChange(event.target.value)}
            {...props}
        />
        {showClear && <CloseIcon className={styles.clearIcon} role="button" onClick={() => onChange("")} />}
        {children && <>{children}</>}
    </div>)
}

export default Input