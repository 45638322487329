import React from 'react';
import { Helmet } from "react-helmet";

const CommunityHelmet = ({ domain }) => {
    let helmet = ''
    if (domain === 'annamoney') {
        helmet = (
            <Helmet>
                <title>ANNA Community by Volee</title>
                <meta name="description" content="Join our growing community of entrepreneurs and start promoting your business for free" />
                <link rel="manifest" href="/community/annamoney/assets/favicons/manifest.json" />
                <link rel="icon" href="/community/annamoney/assets/favicons/favicon.ico" />
                <link rel="icon" type="image/png" sizes="120x120" href="/community/annamoney/assets/favicons/anna-favicon.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="/community/annamoney/assets/favicons/icon192.png" />
                <link rel="icon" type="image/png" sizes="144x144" href="/community/annamoney/assets/favicons/icon144.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/community/annamoney/assets/favicons/icon32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/community/annamoney/assets/favicons/icon16.png" />
                <link rel="mask-icon" href="/community/annamoney/assets/favicons/safari_tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    } else if (domain === 'rc') {
        helmet = (
            <Helmet>
                <title>Сообщество Southub</title>
            </Helmet>
        )
    } else if (domain === 'nw') {
        helmet = (
            <Helmet>
                <title>Сообщество предпринимателей банка Ренессанс Кредит</title>
                <link rel="icon" type="image/png" sizes="120x120" href="/community/nw/assets/favicon.png" />
                <meta property="og:title" content="Сообщество предпринимателей банка Ренессанс Кредит"></meta>
                <meta property="description" name="description" content="Meetsup – комьюнити платформа для создания и управления онлайн сообществами." data-react-helmet="true" />
            </Helmet>
        )
    } else if (domain === 'jewishpoint') {
        helmet = (
            <Helmet>
                <title>Networking JP</title>
                <link rel="icon" type="image/png" sizes="120x120" href="/community/jewishpoint/assets/favicon_jp.png" />
            </Helmet>
        )
    } else if (domain === 'ciao') {
        helmet = (
            <Helmet>
                <title>Ciao, Imprenditore Community</title>
                <link rel="icon" type="image/png" sizes="32x32" href="/community/ciao/assets/favicons/icon32.png" />
            </Helmet>
        )
    } else if (domain === 'multicarta') {
        helmet = (
            <Helmet>
                <title>Сообщество Мультикарта</title>
                <link rel="icon" type="image/png" sizes="32x32" href="/community/multicarta/assets/favicons/icon32.png" />
            </Helmet>
        )
    } else if (domain === 'sfeducation') {
        helmet = (
            <Helmet>
                <title>SF Education Community</title>
                <link rel="icon" type="image/png" sizes="32x32" href="/community/sfeducation/assets/favicons/icon32.png" />
            </Helmet>
        )
    } else if (domain === 'columbiaalumni') {
        helmet = (
            <Helmet>
                <title>Unofficial Columbia Business School Biotech Community</title>
            </Helmet>
        )
    } else if (domain === 'alumni') {
        helmet = (
            <Helmet>
                <title>Constructor Alumni entrepreneurs community</title>
                <meta name="description" content="Open to all Constructor ecosystem members interested in bringing ideas from thoughts to reality." />
                <link rel="manifest" href="/community/alumni/assets/favicons/manifest.json" />
                <link rel="icon" href="/community/alumni/assets/favicons/favicon.ico" />
                <link rel="icon" type="image/png" sizes="192x192" href="/community/alumni/assets/favicons/icon192.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/community/alumni/assets/favicons/icon32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/community/alumni/assets/favicons/icon16.png" />
                <link rel="mask-icon" href="/community/alumni/assets/favicons/safari_tab.svg" color="#00204c" />
                <meta name="msapplication-TileColor" content="#00204c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
        )
    } else {
        helmet = (
            <Helmet>
                <meta property="og:site_name" content={domain} />
                <meta property="og:title" content={domain} />
            </Helmet>
        )
    }
    return helmet
};

export default CommunityHelmet;
