let lastId = 0;

export function newId(prefix = 'id-') {
    lastId++;
    return `${prefix}${lastId}`;
}

export function getDomain() {
    const subdomains = window.location.hostname.split('.');
    if (subdomains.length > 1) {
        return subdomains[0];
    }
    return '';
}

export function malformedTagConfig() {
    return {
        background: "var(--light-border-color)",
        color: "#4F4F4F",
        type: "OTHER",
        value: "Disabled"
    }
}

export function activeTagConfig() {
    return {
        background: "var(--primary-text-color)",
        color: "#fff",
        type: "OTHER",
        value: "Active"
    }
}

export function parseLocation(locationRaw) {
    if (!locationRaw) {
        return '';
    }

    try {
        const parsed = JSON.parse(locationRaw);
        return parsed;
    } catch (e) {
        return locationRaw;
    }
}

export function timeLeft(minutesLeft, locale = 'en') {
    let timeLeft = minutesLeft;
    const days = Math.floor(timeLeft / (60 * 24));
    timeLeft -= days * 60 * 24;
    const hours = Math.floor(timeLeft / 60);
    timeLeft -= hours * 60;
    const minutes = timeLeft;

    return formatRelativeTime(locale, days, hours, minutes)
}

export function formatRelativeTime(locale, days, hours, minutes) {
    const rtf = new Intl.RelativeTimeFormat(locale, {
        numeric: 'auto',
        // style: 'short',
        localeMatcher: 'best fit'
    });

    // Assemble parts only if they are greater than 0
    const parts = [];
    if (days > 0) parts.push(rtf.format(days, 'day'));
    if (hours > 0) parts.push(rtf.format(hours, 'hour'));
    if (minutes > 0) parts.push(rtf.format(minutes, 'minute'));

    // Join parts with a separator, and handle the '0' case
    const timeString = parts.length > 0 ? parts.join(', ') : rtf.format(0, 'minute');
    return timeString.replace(/через /g, '').replace(/in /g, '');
}

export function fullName(participant) {
    return `${participant.firstName || ""} ${participant.lastName || ""}`;
}

export function getTagSet(participant) {
    return participant.tags?.length ? [...new Set(participant.tags
        .filter(item => item !== "Other" && item !== "Другое")
        .map(item => item.length > 50 ? item.substring(0, 47) + "..." : item))
    ] : [];
}

export function emitEvent(eventName, data = {}) {
    const event = new CustomEvent(eventName, { detail: data });
    window.dispatchEvent(event);
}