import { useCommunity } from "../../../context/community";
import { AUTH_TYPES } from "../../../context/AUTH_TYPES";

export function useOneTimeCode() {
    const { community: { authConfig } } = useCommunity();
    const hasCodeAuth = (authConfig?.findIndex(conf => conf.authType === AUTH_TYPES.NONE) ?? -1) >= 0;

    return {
        hasCodeAuth
    }
}
