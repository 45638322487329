import { publicIpv4 } from 'public-ip';

const storageKey = 'public-ip'

function getFromStorage() {
    return sessionStorage.getItem(storageKey);
}

function saveToStorage(ip) {
    sessionStorage.setItem(storageKey, ip);
}


export async function getMyIp() {
    return new Promise(async (resolve, reject) => {
        let ip = getFromStorage();
        if (ip) {
            resolve(ip);
        }
        else {
            try {
                ip = await publicIpv4();
                saveToStorage(ip);
                resolve(ip);
            } catch (e) {
                reject(e);
            }
        }
    })
}

