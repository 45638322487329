import React from 'react';
import styles from "./Loader.module.scss";

const Loader = ({ label, ...props }) => {
    return (
        <div className={styles.loader} {...props}>
            {<span data-spinning='true'></span>}{label}
        </div>
    );
};

export default Loader;