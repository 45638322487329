import PopupNotification from 'components/PopupNotification/PopupNotification';
import React, { useState } from 'react';
import { PopupNotificationContext } from './PopupNotificationContext';

export const PopupNotificationProvider = ({ children }) => {
    const [notificationQueue, setNotificationQueue] = useState([]);

    const showNotification = (notificationTitle, notificationContent, type = 'success', timeout = 3000) => {
        const key = new Date().getTime();
        setNotificationQueue((prevQueue) => [
            ...prevQueue,
            { key, title: notificationTitle, content: notificationContent, timeout, type, shown: true },
        ]);
        setTimeout(() => {
            hideNotification(key);
        }, timeout);
        return key;
    };

    const hideNotification = (key) => {
        setNotificationQueue((prevQueue) =>
            prevQueue.map((notification) =>
                notification.key === key ? { ...notification, shown: false } : notification
            )
        );

        setTimeout(() => {
            setNotificationQueue((prevQueue) => prevQueue.filter((notification) => notification.key !== key));
        }, 1000);
    };

    return (
        <PopupNotificationContext.Provider value={{ notificationQueue, showNotification, hideNotification }}>
            {children}
            <PopupNotification />
        </PopupNotificationContext.Provider>
    );
};
