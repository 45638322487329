import ReactGoogleLogin from "react-google-login";
import LangService from "../../../services/LangService";

import styles from "../Auth.module.scss";

const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;

export const GoogleLogin = ({
    onGoogleSuccess,
    onGoogleFailure
}) => {
    const authMap = LangService.map.pages.Auth;

    return (
        <ReactGoogleLogin
            clientId={clientId}
            buttonText={authMap.googleButtonText}
            cookiePolicy={"single_host_origin"}
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            theme={"dark"}
            responseType={"id_token"}
            className={styles.googleButton}
        />
    )
}