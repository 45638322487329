import { useEffect, useState, useContext, createContext, useCallback } from "react";
import { TOPICS } from "./Subscription";

const OnlineStatusContext = createContext({});

export const OnlineStatusProvider = ({ children }) => {
    const [onlineParticipants, setOnlineParticipants] = useState(new Map());

    const handlePresenceUpdate = (event) => {
        const { participantId, online } = event.detail;
        setOnlineParticipants(prev => {
            const updated = new Map(prev);
            updated.set(participantId, online)
            return updated;
        });
    };

    const isOnline = useCallback((id) => {
        return onlineParticipants.get(id);
    }, [onlineParticipants]);

    useEffect(() => {
        window.addEventListener(TOPICS.PRESENCE, handlePresenceUpdate);
        return () => {
            window.removeEventListener(TOPICS.PRESENCE, handlePresenceUpdate);
        };
    }, [handlePresenceUpdate]);

    return (
        <OnlineStatusContext.Provider value={{ isOnline }}>
            {children}
        </OnlineStatusContext.Provider>
    );
};


export const useOnlineStatus = () => {
    return useContext(OnlineStatusContext);
}