import LangService from "../../services/LangService";

const errorMap = LangService.map.errors
const validation = {
    email: (email) => {
        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return !emailReg.test(email.trim()) ? errorMap.emailFormat : false;
    },
    password: (password) => {
        return !(password.trim().length >= 8 && password.length <= 128) ? errorMap.passwordLength : false;
    },
    confirmPassword: (password, confirmPassword) => {
        return password !== confirmPassword ? errorMap.repeatedPassword : false;
    },
    code: (code) => (!code ? errorMap.code : false),
    policy: (hasPolicy) => !hasPolicy ? errorMap.policy : false
}

export default validation;
