import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { webSockets } from '../../api/websockets/WebSockets';
import { useCurrentUser } from '../community';
import { getToken } from 'services/auth';
import { Subscription, TOPICS } from './Subscription';

const WebSocketContext = createContext({});

export const WebSocketProvider = ({ children }) => {
    const setSubscriptions = useState([])[1];
    const [isConnected, setIsConnected] = useState(false);
    const { connectedParticipants, connectedCommunities } = useCurrentUser();
    const prevParticipantIdRef = useRef();

    const connect = useCallback(() => {
        if (!isConnected) {
            webSockets.connect(getToken(), () => {
                setIsConnected(true);
            });
        }
    }, [isConnected]);

    const disconnect = useCallback(() => {
        if (isConnected) {
            setSubscriptions((currentSubscriptions) => {
                currentSubscriptions.forEach(sub => sub.unsubscribe());
                return [];
            });
            webSockets.disconnect();
            setIsConnected(false);
        }
    }, [isConnected]);

    const sendMessage = useCallback((message, destination) => {
        if (isConnected) {
            webSockets.sendMessage(message, destination);
        }
    }, [isConnected]);

    const subscribe = (topic) => {
        const sub = new Subscription(webSockets, topic, connectedCommunities[0]);
        setSubscriptions((currentSubscriptions) => [...currentSubscriptions, sub]);
    };

    // if logged in - CONNECT, 
    // if logged out - prevParticipantId is present - DISCONNECT
    // if wasn't logged in - SKIP
    useEffect(() => {
        const currentParticipantId = connectedParticipants?.[0];
        const prevParticipantId = prevParticipantIdRef.current;
        if (currentParticipantId && currentParticipantId !== prevParticipantId) {
            connect();
        } else if (!currentParticipantId && prevParticipantId) {
            disconnect();
        }
        prevParticipantIdRef.current = currentParticipantId;
    }, [connectedParticipants, connect, disconnect]);


    // subcribe to predefined topics
    useEffect(() => {
        if (isConnected && connectedCommunities?.[0]) {
            setSubscriptions(
                Object.keys(TOPICS)
                    .map(topic =>
                        new Subscription(webSockets, TOPICS[topic], connectedCommunities[0]))
            );
        }
    }, [isConnected, connectedCommunities])

    const value = {
        subscribe,
        sendMessage
    };

    return (
        <WebSocketContext.Provider value={value}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
