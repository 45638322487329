import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
    getReferenceCommunityId,
    getToken,
    isAdmin, resetAnswers, resetProfile,
    resetToken, setLoggedIn, setReferenceCommunityId,
    setRole,
    setFirstLogin,
    setToken,
    resetEmail,
    resetUserData,
    getInvitedBy,
    resetInvitedBy,
} from "../services/auth";
import { voleeApi } from "../api/VoleeApi";
import { PAGES } from "../routes/PAGES";
import { useHistory, useLocation } from "react-router-dom";
import LangService from "../services/LangService";
import queryString from "query-string";
import CommunityHelmet from "./CommunityHelmet";
import { getDomain } from "utils";
import { getFirstLogin } from "../services/auth";

const defaultSteps = {
    stepsConfig: {
        profile: {
            visible: true,
        },
        lookingFor: {
            visible: true
        },
        contacts: {
            visible: true
        },
        networks: {
            visible: true
        },
        avatar: {
            visible: true
        },
        about: {
            visible: true
        },
    }
}

const defaultContacts = {
    contactsConfig: [],
    requiredContacts: ["FACEBOOK", "LINKEDIN"],
    requiredContactsMin: 1,
    shownContacts: ["TELEGRAM", "INSTAGRAM", "LINKEDIN", "FACEBOOK"],
}

const defaultBrand = {
    senderEmail: "",
    brandConfig: {
        title: "",
        logoUrl: "",
        siteUrl: ""
    }
}
const defaultCommunity = {
    domain: "volee",
    landingTitle: "Start networking with members of the community",
    imgUrl: "",
    landingCopy: "",
    title: "",
    lang: "en",
    transparent: false,
    ...defaultContacts,
    ...defaultSteps,
    ...defaultBrand
}

const communityContext = createContext({});

export function ProvideCommunity({ children }) {
    const community = useProvideCommunity();

    return (
        <communityContext.Provider value={community}>
            <CommunityHelmet domain={community.community?.domain} />
            {children}
        </communityContext.Provider>
    );
}

export function useCommunity() {
    return useContext(communityContext);
}

export function useCurrentUser() {
    const { currentUser } = useContext(communityContext);
    return currentUser;
}

export function useUserProfile() {
    const { userProfile } = useContext(communityContext);
    return userProfile;
}

export function useProvideCommunity() {
    const history = useHistory();
    const location = useLocation();
    const [currentUser, setCurrentUser] = useState({})
    const [userProfile, setUserProfile] = useState({})
    const [community, setCommunityState] = useState(defaultCommunity);
    const [loading, setLoading] = useState(true);
    const langChoicesRef = useRef(null);

    const setCommunity = (community) => {
        const langChoices = community.langChoices;
        const localLang = localStorage.getItem("local");
        langChoicesRef.current = langChoices;
        if (langChoices && langChoices.length && langChoices.includes(localLang)) {
            LangService.lang = localLang;
        } else {
            LangService.lang = community.lang;
        }
        setCommunityState({
            ...defaultContacts,
            ...defaultSteps,
            ...defaultBrand,
            ...community
        });
    }

    const updateCurrentUser = async () => {
        const updatedCurrentUser = await voleeApi.general.currentUser();
        const userLang = updatedCurrentUser.preferredLang;
        const langChoices = langChoicesRef.current;
        if (langChoices && langChoices.length && langChoices.includes(userLang)) {
            LangService.lang = userLang;
        }

        gtag('set', {
            user_id: updatedCurrentUser.userId
        })
        if (updatedCurrentUser.communityUrl &&
            updatedCurrentUser.connectedCommunities &&
            updatedCurrentUser.connectedCommunities[0] &&
            updatedCurrentUser.connectedCommunities[0] !== getReferenceCommunityId()) {
            const token = getToken();
            const firstLogin = getFirstLogin();
            resetUserData();
            window.location.replace(`${updatedCurrentUser.communityUrl}?token=${token}&firstLogin=${firstLogin}`);
            return;
        }
        setCurrentUser(updatedCurrentUser);
        return updatedCurrentUser;
    }

    const updateUserProfile = async (participantId) => {
        const updatedUserProfile = await voleeApi.participant.getProfile(participantId);
        setUserProfile(updatedUserProfile);
    }

    const updateCommunityData = (refCommunity) => {
        refCommunity.id && setReferenceCommunityId(refCommunity.id);
        setCommunity(refCommunity);
        gtag('set', {
            community: refCommunity.domain
        })
        return true;
    }

    const fetchCommunityData = async () => {
        let wasFoundCommunity = false;
        const domain = getDomain();
        if (domain) {
            wasFoundCommunity = await voleeApi.community.getByDomain(domain)
                .then(updateCommunityData)
                .catch(() => false);
        }

        const q = queryString.parse(location.search)
        if (q.token) {
            resetUserData();
            setToken(q.token);
            setFirstLogin(q.firstLogin);
        }
        const referenceCommunityId = q.communityId || getReferenceCommunityId();
        if (!wasFoundCommunity && referenceCommunityId) {
            wasFoundCommunity = await voleeApi.community.get(referenceCommunityId)
                .then(updateCommunityData)
                .catch(() => false);
        }

        if (!wasFoundCommunity) {
            updateCommunityData(defaultCommunity);
        }
    }

    const resetData = async (backTo) => {
        setCurrentUser({});
        if (backTo) {
            history.push(backTo || PAGES.WELCOME);
        }
        await fetchCommunityData();
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchCommunityData();

            // Update current user
            const token = getToken();
            if (!token) {
                setLoading(false);
                return;
            }

            try {
                const { connectedParticipants, role, enabled } = await updateCurrentUser();
                setRole(role || '');

                if (isAdmin()) {
                    // proceed to whatever page is needed
                    return;
                }

                const participantId = connectedParticipants?.[0];
                if (!participantId) {
                    history.push(PAGES.QUESTIONS);
                    return;

                }
                if (enabled === false) {
                    history.push(PAGES.PROFILE);
                    return;
                }
                await updateUserProfile(connectedParticipants[0]);

            } catch (e) {
                // There is a token, but old, need only to reset expired token without redirecting to login page
                if (location.pathname === PAGES.AUTH_MAGIC) {
                    return;
                }
                resetToken();
            } finally {
                setLoading(false);
            }

        })()
    }, []);

    return {
        currentUser,
        userProfile,
        updateCurrentUser,
        updateUserProfile,
        fetchCommunityData,
        community,
        setCommunity,
        loading,
        setLoading,
        resetData,
    };
}

const magicAuth = async (authContext) => {
    return voleeApi.auth.magicAuth({
        url_auth_token: authContext.token,
        back_to: authContext.backTo,
    })
}
const googleAuth = async (authContext) => {
    return voleeApi.auth.loginGoogle({
        token: authContext.token,
    });
}
const passwordAuth = async (authContext) => {
    const form = {
        login: authContext.email,
        password: authContext.password,
    };
    return voleeApi.auth.login(form);
}

const otpAuth = async (authContext) => {
    const form = {
        login: authContext.email,
        password: authContext.password,
    };
    return voleeApi.auth.otp.validate(form);
}

const authHandlers = {
    'MAGIC': magicAuth,
    'GOOGLE': googleAuth,
    'EMAIL': passwordAuth,
    'OTP': otpAuth,
}

/*
authContext = {
    authType,
    email?,
    password?,
    token?,
    backTo?,
    introFor?
}
*/
export function useLogin() {
    const history = useHistory();
    const { updateCurrentUser, fetchCommunityData } = useCommunity();

    const tryAcceptInvitation = async () => {
        try {
            const invitedBy = getInvitedBy();
            if (invitedBy) {
                await voleeApi.auth.acceptInvite(invitedBy);
            }
        } catch (e) {
            console.warn(e);
        }
        finally {
            resetInvitedBy();
        }
    }

    const tryConnectTelegram = async (userId) => {
        if (userId) {
            //We've got a userId - user has logged in
            const { chatId, username } = window.parsedTelegramUserInfo;
            if (chatId) {
                //We've got a chatId - this is a Telegram MiniApp
                try {
                    const result = await voleeApi.telegram.connect({ userId, chatId, username });
                    console.debug('Telegram connected:', result);
                } catch (e) {
                    console.warn('Telegram connection error:', e);
                }
            }
        }
    }

    return async (authContext) => {
        const { token, firstLogin } = await authHandlers[authContext.authType](authContext);
        const backTo = authContext.backTo;
        const introFor = authContext.introFor;
        setToken(token);
        setFirstLogin(firstLogin);
        setLoggedIn();
        resetProfile();
        resetAnswers();
        resetEmail();

        if (!getReferenceCommunityId()) {
            await fetchCommunityData();
        }

        const currentUser = await updateCurrentUser();
        //It happens in case of redirect to another community
        if (!currentUser) {
            return;
        }
        setRole(currentUser.role || '');
        const admin = isAdmin();

        if (admin) {
            if (typeof backTo === 'string') {
                return history.push(PAGES[backTo.toUpperCase()] || PAGES.EVENT);
            }
            return history.push(PAGES.EVENT);
        }

        if (firstLogin) {
            await tryAcceptInvitation();
        }

        if (!currentUser.connectedParticipants.length) {
            await tryConnectTelegram(currentUser.userId);
            return history.push(PAGES.QUESTIONS);
        }
        if (typeof backTo === 'string') {
            return history.push(PAGES[backTo.toUpperCase()] || PAGES.MY_COMMUNITIES, { introMessageForId: introFor });
        }
        return history.push(PAGES.MY_COMMUNITIES);

    }
}
