import { emitEvent } from "utils";

export const TOPICS = {
    PRESENCE: "PRESENCE",
}

const topicTemplates = {
    "PRESENCE": (communityId, participantId, userId) => `community/${communityId}/presence`,
}

export class Subscription {
    constructor(websocket, topic, communityId, participantId, userId) {
        this.topic = topic;
        this.nativeTopic = topicTemplates[topic](communityId, participantId, userId);
        this.nativeSubscription = websocket.subscribe(this.nativeTopic, data => emitEvent(this.topic, data))
    }

    getTopic() {
        return this.topic;
    }

    unsubscribe() {
        this.nativeSubscription.unsubscribe();
    }
}
