import React, { Suspense, lazy, useEffect, useRef } from "react";
import styles from "./Header.module.scss";
import { Col, Container, Row } from "react-grid-system";
import MiniLogo from "../Logo/MiniLogo";
import { NavLink, useHistory } from "react-router-dom";
import { PAGES } from "../../routes/PAGES";
import { useCommunity, useCurrentUser } from "../../context/community";
import LoginButton from "../LoginButton/LoginButton";
import LangService from "../../services/LangService";
import LangSelector from "./components/LangSelector";
import NavBar from "../NavBar/NavBar";
import cn from "classnames";
import LandingHeader from "./components/LandingHeader";

const Notifications = lazy(() => import('components/Notifications/Notifications'));
const DropdownAvatar = lazy(() => import('components/DropdownAvatar/DropdownAvatar'));

const IS_SHOWING_ALREADY_MEMBER = [PAGES.WELCOME];

const SHOULD_BE_HIDDEN = [
  PAGES.LOGIN,
  PAGES.REGISTRATION
];

const Header = () => {
  const history = useHistory();
  const fixedNavBarRef = useRef(null);
  const { userId, connectedParticipants } = useCurrentUser();
  const { community: { domain, langChoices, shortLanding, isTimCommunity } } = useCommunity();
  const textMap = LangService.map.components.logout;
  const isShowingAlreadyMember = IS_SHOWING_ALREADY_MEMBER.includes(
    history.location.pathname
  );
  const isHidden = SHOULD_BE_HIDDEN.includes(history.location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      const fixedNavBar = fixedNavBarRef.current;
      if (fixedNavBar) {
        if (window.scrollY > 78) {
          fixedNavBar.style.display = "block";
          fixedNavBar.style.opacity = "1";
        } else {
          fixedNavBar.style.display = "none";
          fixedNavBar.style.opacity = "0";
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (isTimCommunity && history.location.pathname === PAGES.WELCOME) {
    return <LandingHeader />;
  }

  return (
    <>
      <header className={styles.header}>
        <Container className={cn(isHidden ? styles.headerContainerLite : styles.headerContainer, {
          [styles.shortLanding]: shortLanding
        })}>
          <Row
            justify="between"
            align="center"
            className={styles.headerRow}
            wrap="nowrap"
          >
            <Col width={200} className={styles.logoCol}>
              <NavLink to={PAGES.WELCOME} onClick={() => {
                gtag('event', 'header_logo_click')
              }}>
                {!isHidden ? <MiniLogo /> : null}
              </NavLink>
            </Col>
            <Col xs="content" className={styles.loginCol}>
              <LangSelector langChoices={langChoices} />
              {userId && (
                <Suspense fallback={<div></div>}>
                  {Boolean(connectedParticipants?.length) && <Notifications />}
                  <DropdownAvatar />
                </Suspense>
              )}
              {!userId && (
                <>
                  {isShowingAlreadyMember && !shortLanding && (
                    <div className={styles.alreadyMember}>
                      {textMap.alreadyMember}
                    </div>
                  )}
                  {domain !== 'play' &&
                    <LoginButton />
                  }
                </>
              )}
            </Col>
          </Row>
        </Container>
      </header>
      <Container className={styles.navBarContainer}>
        <Row justify="start">
          <NavBar showDonation />
        </Row>
      </Container>
      <div className={styles.fixedNavbar} ref={fixedNavBarRef}>
        <Container className={styles.navBarContainer}>
          <Row justify="start">
            <NavBar />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Header;
