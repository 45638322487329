import React from 'react';
import { Col, Hidden, Row, Visible, Container } from 'react-grid-system'
import cn from 'classnames';
import styles from './Skeleton.module.scss';
import { PAGES } from 'routes/PAGES';

const Filters = () => {
    return <>
        <div className={styles.filter} data-loading="true" style={{ width: "102px" }} />
        <div className={styles.filter} data-loading="true" style={{ width: "144px" }} />
        <div className={styles.filter} data-loading="true" style={{ width: "122px" }} />
    </>
}

const AvatarCol = () => {
    return <Col xs={4} md={3} className={styles.col}>
        <div className={styles.avatars}>
            <div className={styles.avatar}></div>
        </div>
    </Col>
}

const LeftPanelCol = () => {
    return <Col md={8} xs={12} className={styles.col}>
        <div className={styles.panel}>
        </div>
    </Col>
}

const RequestCol = () => {
    return <Col xs={12} className={styles.col} style={{ paddingRight: 0 }}>
        <div className={styles.cards}>
            <div className={styles.panel} style={{ width: "125%", height: "172px" }}></div>
            <div className={styles.panel} style={{ width: "290px", height: "172px" }}></div>
        </div>
    </Col>
}

const RightPanelCol = () => {
    return <Col md={4} xs={12} className={styles.col}>
        <Visible md lg xl>
            <div className={styles.buttons} style={{ marginTop: "16px" }}>
                <div className={styles.text}></div>
                <div className={styles.button} style={{ minWidth: "40px" }}></div>
            </div>
            <div className={styles.label}></div>
            <div className={styles.label}></div>
        </Visible>
    </Col>
}

const TitleCol = ({ showButton = true, style }) => {
    return <Col xs={12} lg={7} xl={6} className={styles.col} style={style}>
        <div className={styles.title}>
            <div className={styles.text}></div>
            {showButton && <Hidden lg xl>
                <div className={styles.button}></div>
            </Hidden>}
        </div>
    </Col>
}

const SubtitleCol = () => {
    return <Col xs={12} lg={5} className={styles.col}>
        <div className={styles.subtitle}>
        </div>
    </Col>
}

const TextCol = () => {
    return <Col xs={8} md={6} className={styles.col}>
        <div className={styles.texts}>
            <div className={styles.label}></div>
            <div className={styles.label}></div>
            <div className={styles.text}></div>
            <div className={styles.tags}>
                <div className={styles.tag}></div>
                <div className={styles.tag}></div>
                <div className={styles.tag}></div>
                <div className={styles.tag}></div>
            </div>
        </div>
    </Col>
}

const ButtonCol = () => {
    return <Col md={3} className={styles.col}>
        <div className={styles.label}></div>
        <div className={styles.buttons}>
            <div className={styles.button}></div>
            <div className={styles.button}></div>
        </div>
    </Col>
}

const LeftInputCol = () => {
    return <div className={cn(styles.col, styles.profileCol)}>
        <div className={styles.inputs}>
            <div className={styles.buttons}>
                <div className={styles.input}></div>
                <div className={styles.input}></div>
            </div>
            <div className={styles.input}></div>
            <div className={styles.panel}></div>
            <div className={styles.input}></div>
        </div>
    </div>
}

const RightInputCol = () => {
    return <div className={cn(styles.col, styles.profileCol)}>
        <div className={styles.inputs}>
            <div className={styles.input}></div>
            <div style={{ borderRadius: "8px", overflow: "hidden" }}>
                <div className={styles.input} style={{ borderRadius: 0 }}></div>
                <div className={styles.input} style={{ borderRadius: 0 }}></div>
            </div>
            <div className={styles.tag}></div>
            <div style={{ marginBottom: "-19px" }}></div>
            <div className={styles.buttons}>
                <div className={styles.text}></div>
                <div className={styles.button}></div>
            </div>
            <div className={styles.buttons}>
                <div className={styles.text}></div>
                <div className={styles.button}></div>
            </div>
        </div>
    </div>
}

const ProfileAvatar = () => {
    return <div className={cn(styles.col, styles.profileCol, styles.avatarCol)}>
        <div className={styles.profileAvatar}></div>
        <div className={styles.tag}></div>
    </div>
}

const ProfileButtons = () => {
    return <Col xs={6} className={styles.col}>
        <div className={styles.profileButtons}>
            <div className={styles.text}></div>
            <div className={styles.text}></div>
        </div>
    </Col>
}

const SurveyTitleCol = () => {
    return <Col xs={12} className={styles.col}>
        <div className={styles.text}></div>
        <div className={styles.label}></div>
    </Col>
}

const SurveyAnswersCol = () => {
    return <Col xs={12} className={styles.col}>
        <div className={styles.pills}>
            <div className={styles.pill} style={{ width: "70px" }}></div>
            <div className={styles.pill} style={{ width: "30px" }}></div>
            <div className={styles.pill} style={{ width: "50px" }}></div>
        </div>
        <div className={styles.pills}>
            <div className={styles.pill} style={{ width: "140px" }}></div>
            <div className={styles.pill} style={{ width: "60px" }}></div>
            <div className={styles.pill} style={{ width: "30px" }}></div>
        </div>
    </Col>
}

const SurveyButtonCol = () => {
    return <Col xs={12} sm={6} className={styles.col}>
        <div className={styles.profileButtons}>
            <div className={styles.input}></div>
        </div>
    </Col>
}

const NameCol = () => {
    return <Col xs={6} md={3} className={cn(styles.col, styles.nameCol)} style={{ justifyContent: "center", gap: "20px" }}>
        <Hidden xs >
            <div className={styles.text}></div>
        </Hidden>
        <div className={styles.label}></div>
        <div className={styles.label}></div>
    </Col>
}

const ActionButtonsCol = () => {
    return <Col xs={4} md={6} className={styles.col} style={{ alignItems: "flex-end", justifyContent: "center" }}>
        <div className={styles.buttons}>
            <div className={styles.button}></div>
            <div className={styles.button}></div>
            <div className={styles.button}></div>
        </div>
        <div className={styles.tag} style={{ maxWidth: "144px" }}></div>
    </Col>
}

const Wrapper = ({ useContainer, children }) => {
    return useContainer ? <Container className={styles.gridContainer}>{children}</Container> : <div>{children}</div>;
}

const Skeleton = ({ page, component, useContainer }) => {
    if (component === "Filter") {
        return <Filters />
    }
    if (page == "RequestsMobile") {
        return <Wrapper useContainer={useContainer}>
            <div className={styles.skeleton}>
                <Row className={styles.row} style={{ marginBottom: "6px" }}>
                    <RequestCol />
                </Row>
            </div>
        </Wrapper>
    }
    if (page === PAGES.FULL_PARTICIPANT_VIEW) {
        return <Wrapper useContainer={useContainer}>
            <div className={styles.skeleton}>
                <Row className={styles.row}>
                    <Hidden xs >
                        <AvatarCol />
                    </Hidden>
                    <Visible xs>
                        <div style={{ position: "relative", left: "16px", marginRight: "-40px", marginBottom: "-10px" }}>
                            <AvatarCol />
                        </div>
                    </Visible>
                    <NameCol />
                    <Hidden xs sm>
                        <ActionButtonsCol />
                    </Hidden>
                </Row>
                <Row className={styles.row}>
                    <Hidden md>
                        <TextCol />
                    </Hidden>
                    <Visible md>
                        <Col style={{ left: "-70px", top: "-6px" }} md={12}>
                            <TextCol />
                        </Col>
                    </Visible>
                </Row>
            </div>
        </Wrapper>
    }

    if (page === PAGES.QUESTIONS || page === PAGES.ONBOARDING) {
        return <Wrapper useContainer={useContainer}>
            <div className={cn(styles.skeleton, styles.surveySkeleton)}>
                <Row className={styles.row}>
                </Row>
                <Row className={styles.row}>
                    <SurveyTitleCol />
                </Row>
                <Row className={styles.row}>
                    <SurveyAnswersCol />
                </Row>
                <Row className={styles.row}>
                </Row>
                <Row className={styles.row}>
                    <SurveyButtonCol />
                    <SurveyButtonCol />
                </Row>
            </div>
        </Wrapper>
    }

    if (page === PAGES.PROFILE) {
        return (
            <Wrapper useContainer={useContainer}>
                <div className={styles.skeleton}>
                    <Row className={styles.row}>
                        <Visible lg xl>
                            <div className={styles.profile}>
                                <LeftInputCol />
                                <RightInputCol />
                                <ProfileAvatar />
                            </div>
                        </Visible>
                        <Visible xs sm md>
                            <ProfileAvatar />
                            <div className={styles.profile}>
                                <LeftInputCol />
                                <RightInputCol />
                            </div>
                        </Visible>
                        <ProfileButtons />
                    </Row>
                </div>
            </Wrapper>

        )
    }

    return (
        <Wrapper useContainer={useContainer}>
            <div className={styles.skeleton}>
                {page === PAGES.MY_COMMUNITIES && (
                    <Row className={styles.row}>
                        <TitleCol />
                        <LeftPanelCol />
                        <RightPanelCol />
                        <SubtitleCol />
                    </Row>
                )}
                {page === PAGES.PARTICIPANTS && (
                    <Row className={styles.row}>
                        <TitleCol showButton={false} style={{ marginBottom: "-8px" }} />
                        <Col xs={12} className={styles.col} style={{ flexDirection: 'row', gap: "8px" }}>
                            <Filters />
                        </Col>
                    </Row>
                )}
                <Row className={styles.row}>
                    <Hidden xs sm>
                        <AvatarCol />
                        <TextCol />
                        <ButtonCol />
                    </Hidden>

                    <Visible xs sm>
                        <TextCol />
                        <AvatarCol />
                    </Visible>
                </Row>
                <Row className={styles.row}>
                    <Hidden xs sm>
                        <AvatarCol />
                        <TextCol />
                        <ButtonCol />
                    </Hidden>

                    <Visible xs sm>
                        <TextCol />
                        <AvatarCol />
                    </Visible>
                </Row>
                <Row className={styles.row}>
                    <Hidden xs sm>
                        <AvatarCol />
                        <TextCol />
                        <ButtonCol />
                    </Hidden>

                    <Visible xs sm>
                        <TextCol />
                        <AvatarCol />
                    </Visible>
                </Row>
            </div>
        </Wrapper>
    );
};

export default React.memo(Skeleton);
