import React, { useState } from 'react';
import styles from "./Logo.module.scss"
import cn from 'classnames';
import { useCommunity } from "../../context/community";

const MiniLogo = ({ className }) => {
    const { community: { logoUrl } } = useCommunity();
    const [loaded, setLoaded] = useState(false);
    return (
        <img
            onLoad={() => setLoaded(true)}
            src={logoUrl}
            alt="logo"
            style={{ opacity: loaded ? 1 : 0 }}
            className={cn(styles.miniLogo, className)}
        />
    )
}

export default MiniLogo;